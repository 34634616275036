import { useApiManual } from '@lasso/shared/hooks'
import { nonNullable } from '@lasso/shared/utils'
import { partition } from 'lodash-es'

import { useActivationApi } from '@lasso/api-activation/activation'

import { isAdvancedManagementAvailable } from './utils'
import { FrequencyFormInput, FrequencyFormOutput } from './types'

export const useCardFrequencySubmit = () => {
  const api = useActivationApi()
  const { requestThrows: createFrequencies } = useApiManual(api.addAdGroupFrequencies)
  const { requestThrows: updateFrequencies } = useApiManual(api.updateAdGroupFrequencies)
  const { requestThrows: deleteFrequencies } = useApiManual(api.deleteAdGroupsFrequencies)

  const upsertFrequencies = async ({
    adGroupId,
    frequency,
    initialFrequency,
    isDirectSold,
  }: {
    adGroupId: number
    frequency: FrequencyFormOutput
    initialFrequency: FrequencyFormInput
    isDirectSold: boolean
  }) => {
    const notEmptyCaps = frequency.frequencyCaps.filter(item => nonNullable(item.value) && nonNullable(item.timeUnit))
    const capIds = notEmptyCaps.map(cap => cap.id)
    const initialCapIds = initialFrequency.frequencyCaps.filter(cap => cap.id).map(cap => cap.id)

    const toDelete = initialCapIds.filter(id => !capIds.includes(id))
    const [toUpdate, toCreate] = partition(notEmptyCaps, cap => initialCapIds.includes(cap.id))

    await Promise.all([
      toDelete.length > 0 ? deleteFrequencies({ iDs: toDelete }) : null,
      toUpdate.length > 0
        ? updateFrequencies({
          adGroupID: adGroupId,
          adGroupFrequenciesArray: toUpdate.map(item => ({
            id: item.id,
            frequencyTimeUnit: item.timeUnit ?? undefined,
            frequencyValue: item.value ?? undefined,
            advancedManagement: isAdvancedManagementAvailable(!isDirectSold, item) ? item.advancedManagement : undefined,
          })),
        })
        : null,
      toCreate.length > 0
        ? createFrequencies({
          adGroupID: adGroupId,
          adGroupFrequenciesArray: toCreate.map(item => ({
            frequencyTimeUnit: item.timeUnit ?? undefined,
            frequencyValue: item.value ?? undefined,
            advancedManagement: isAdvancedManagementAvailable(!isDirectSold, item) ? item.advancedManagement : undefined,
          })),
        })
        : null,
    ])
  }

  return { upsertFrequencies }
}
