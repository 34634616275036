<template>
  <Card
    title="Frequency & Recency"
    collapse
    withDivider
    opened
  >
    <Skeleton v-if="loading" height="320px" />
    <ErrorState v-else-if="error" :error="error" @retry="request()" />
    <Box v-else flex direction="col" spaceY="6">
      <FormSection title="Add frequency cap">
        <Box flex direction="col" spaceY="4" mb="4">
          <Card
            v-for="(cap, index) in frequencyCaps"
            :key="index"
            variant="outlined"
            shadow="none"
            py="4"
            disablePY
            class="relative"
            width="fit-content"
          >
            <Box flex spaceX="6" spaceY="4" wrap="wrap" alignItems="start" justify="start" pr="4">
              <FormControl
                :id="getId(`frequency.frequencyCaps[${index}].value`)"
                v-slot="{ id }"
                width="160px"
              >
                <InputText
                  :id="id"
                  :modelValue="cap.value"
                  :mask="numberMask"
                  @update:modelValue="updateFrequencyCap(index, { value: $event })"
                />
              </FormControl>

              <Typography variant="body2" color="textPrimary" pt="3.5">
                {{ pluralizeImpression(cap.value) }} per
              </Typography>

              <FormControl
                :id="getId(`frequency.frequencyCaps[${index}].timeUnit`)"
                v-slot="{ id }"
                width="160px"
              >
                <Select
                  :id="id"
                  :modelValue="cap.timeUnit || undefined"
                  :options="frequencyCapTimeframes"
                  width="160px"
                  placeholder="Select"
                  cancelable
                  @update:modelValue="updateFrequencyCap(index, { timeUnit: $event || null })"
                />
              </FormControl>

              <FieldControl
                v-if="advancedManagementAvailable(cap)"
                :id="getId(`frequency.frequencyCaps[${index}].advancedManagement`)"
                v-slot="{ id }"
                label="Advanced frequency management"
                pt="5"
              >
                <Toggle
                  :id="id"
                  :modelValue="cap.advancedManagement"
                  size="xs"
                  color="secondary"
                  @update:modelValue="updateFrequencyCap(index, { advancedManagement: $event })"
                />
              </FieldControl>

              <ButtonIcon
                v-if="canDeleteFrequencyCap"
                icon="close"
                class="delete-frequency-btn"
                @click="removeFrequencyCap(index)"
              />
            </Box>
          </Card>
        </Box>

        <AddLink v-if="canAddFrequencyCap" @click="addFrequencyCap">
          Frequency Cap
        </AddLink>
      </FormSection>

      <FormSection title="Add recency cap">
        <Card variant="outlined" shadow="none" py="4" disablePY width="fit-content">
          <Box flex spaceX="6" direction="row" wrap="wrap" alignItems="start" justify="start" pr="4">
            <Typography variant="body2" color="textPrimary" pt="3.5">
              1 impression per
            </Typography>

            <FormControl :id="getId('recency.recencyValue')" v-slot="{ id }" width="160px">
              <InputText :id="id" v-model="recencyValue" :mask="numberMask" />
            </FormControl>

            <FormControl :id="getId('recency.recencyTimeUnit')" v-slot="{ id }" width="160px">
              <Select
                :id="id"
                v-model="recencyTimeUnitInternal"
                :options="recencyTimeFrames"
                width="160px"
                placeholder="Select"
                cancelable
              />
            </FormControl>
          </Box>
        </Card>
      </FormSection>
    </Box>
  </Card>
</template>

<script setup lang="ts">
import {
  AddLink,
  Box,
  ButtonIcon,
  Card,
  ErrorState,
  FieldControl, FormControl, FormSection, InputText, Select,
  Skeleton,
  Toggle,
  Typography,
} from '@lasso/luikit'
import { FrequencyTimeUnit, RecencyTimeUnitType } from '@lasso/api-activation/activation'
import { numberMask } from '@lasso/shared/consts'
import { computed } from 'vue'

import { useCardFrequency } from './useCardFrequency'

const {
  frequencyCaps,
  recencyValue,
  recencyTimeUnit,
  loading,
  error,
  request,

  canAddFrequencyCap,
  canDeleteFrequencyCap,
  advancedManagementAvailable,

  updateFrequencyCap,
  removeFrequencyCap,
  addFrequencyCap,
  getId,
} = useCardFrequency()!

const frequencyCapTimeframes = [
  {
    label: 'Hour',
    value: FrequencyTimeUnit.HOUR,
  },
  {
    label: 'Day',
    value: FrequencyTimeUnit.DAY,
  },
  {
    label: 'Week',
    value: FrequencyTimeUnit.WEEK,
  },
  {
    label: 'Month',
    value: FrequencyTimeUnit.MONTH,
  },
  {
    label: 'Lifetime',
    value: FrequencyTimeUnit.LIFETIME,
  },
]

const recencyTimeFrames = [
  {
    label: 'Minute',
    value: RecencyTimeUnitType.MINUTE,
  },
  {
    label: 'Hour',
    value: RecencyTimeUnitType.HOUR,
  },
  {
    label: 'Day',
    value: RecencyTimeUnitType.DAY,
  },
  {
    label: 'Week',
    value: RecencyTimeUnitType.WEEK,
  },
  {
    label: 'Month',
    value: RecencyTimeUnitType.MONTH,
  },
]

const recencyTimeUnitInternal = computed({
  get: () => recencyTimeUnit.value ?? undefined,
  set: (value) => {
    recencyTimeUnit.value = value ?? null
  },
})

const pluralizeImpression = (num: number | null) => {
  return num === 1 ? 'impression' : 'impressions'
}
</script>

<style scoped>
.delete-frequency-btn {
  @apply absolute right-4 top-2;
}
</style>
